<script setup lang="ts">
import Tracker from '~/libs/Tracker';
import { getDurationInDays } from '~/libs/dateUtils';

import Utility from '~/services/Utility';
import { useCommonStore } from '~/services/common';
import { interestInfo } from '~/services/interest/composable/useInterestInfo';
import { useMainStore } from '~/services/main';
import { useUserProfileStore } from '~/services/profile/store';

const route = useRoute();
const router = useRouter();
const { isMobile } = useMobile();
const mainStore = useMainStore();
const commonStore = useCommonStore();
const userProfileStore = useUserProfileStore();
const isEmpty = $isEmpty;

const topBanner = computed(() => {
  const bannerList = mainStore.designModuleMainList.items
    .filter((v) => v.useYn === 'Y')
    .filter((v) => v.designModuleType === 'BANNER' && v.designType === 'MAIN');
  if (isEmpty(bannerList)) {
    return null;
  }
  return bannerList[0];
});

const onClickMainBanner = () => {
  if ($isEmpty(mainStore.designModuleMainList.items)) {
    return;
  }
  Tracker['Click Main Top Banner']();
  Utility.openLink(mainStore.designModuleMainList.items[0].linkUrl, mainStore.designModuleMainList.items[0].linkType);
};

function onCloseMainBanner() {
  mainStore.visibleTopBanner = false;
  if (topBanner.value?.designSeq != null) {
    Utility.cacheLastOpenSeq({
      seq: topBanner.value?.designSeq,
      type: 'designSeq',
    });
  }
}

const onClickInterestBanner = () => {
  Tracker['Click Main Top Banner']('interest-banner');
  const redirectUrl = userProfileStore.profile.selectedInterest
    ? interestInfo[userProfileStore.profile.selectedInterest.interestId]?.redirectUrl
    : '/';
  router.push(redirectUrl);
};

const onCloseInterestBanner = () => {
  mainStore.visibleTopBanner = false;
  if (topBanner.value?.designSeq != null) {
    Utility.cacheLastOpenSeq({
      seq: topBanner.value?.designSeq,
      type: 'designSeq',
    });
  }
};

const showBandBanner = computed<boolean>(() => {
  if (!mainStore.visibleTopBanner) return false; // 띠배너 끄고 24시간 동안 안보기 정책이 적용되어있음, Utility.hasCachedInOneDay
  if (!isMobile.value) return !isEmpty(topBanner.value); // 모바일이 아니고, 띠배너가 있을 때만 노출
  if (route.path.startsWith('/search')) return false; // 검색 페이지에서는 띠배너 노출 안함
  return !commonStore.getMyPageHeadHidePathList.includes(route.path.replace(/\/\s*$/, '')); // 띠배너 노출 되면 안되는 페이지
});
// 관심사 띠배너 노출 여부
// signUpPath : ETC
// selectedInterest : 관심사를 선택했고
// paymentFunnel : 결제를 한번도 안한 경우 - 'ZERO' 인경우, 원래는 fetchMyCoupons 에서 "couponSeq": 456 이 있는지 였는데 마이페이지 api 를 또 호출 하는것이라서 이정책으로 변경 요청함
// regDate 즉 가입일로부터 7일 이내인 경우
const showInterestBanner = computed<boolean>(() => {
  // console.log('showInterestBanner', userProfileStore.profile);
  if (isEmpty(userProfileStore.profile)) {
    return false;
  }

  const days = getDurationInDays(userProfileStore.profile.regDate, new Date());

  const isTargetUserSegment =
    userProfileStore.profile.signUpPath === 'ETC' || userProfileStore.profile.signUpPath === 'CLASS';

  if (
    isTargetUserSegment &&
    !isEmpty(userProfileStore.profile.selectedInterest) &&
    userProfileStore.profile.paymentFunnel === 'ZERO' &&
    days < 7
  ) {
    return true;
  }
  return false;
});
</script>
<template>
  <div v-if="showBandBanner">
    <div
      v-if="showInterestBanner"
      class="wb-banner-top h-[90px]"
      :style="{ backgroundColor: Utility.getHex('#5886ff') }">
      <div class="pc relative cursor-pointer" @click="onClickInterestBanner">
        <img
          :src="`${$cdn()}/fe/main/interest_pc.png?s=lg&q=95`"
          alt="banner"
          class="w-full block max-w-[644px] m-auto cursor-pointer"
          loading="lazy" />
        <div
          class="absolute top-0 left-0 right-0 bottom-0 h-full max-w-[300px] m-auto text-white text-center text-base font-bold leading-6 flex flex-col items-center justify-center">
          <div>
            첫강의로 가장 많이 선택한
            <span class="text-[#ffd905]">{{ userProfileStore.profile?.selectedInterest?.description }}</span> 강의!
          </div>
          <div class="flex">
            <span class="text-[#ffd905] max-w-[90px] text-ellipsis inline-block whitespace-nowrap overflow-hidden">{{
              userProfileStore.profile?.nickName || userProfileStore.profile?.userName
            }}</span
            ><span class="text-[#ffd905] inline-block">님</span>도 최대 할인 받고 수강하세요.
          </div>
          <div class="text-[#a1bbff] text-sm font-normal">이 혜택은 곧 사라져요!</div>
        </div>
      </div>
      <div class="mo relative cursor-pointer" @click="onClickInterestBanner">
        <div class="flex h-full flex-row justify-between max-w-[414px] m-auto cursor-pointer">
          <img :src="`${$cdn()}/fe/main/interest_mo_left_4x.webp`" alt="banner" class="block w-[80px]" loading="lazy" />
          <img
            :src="`${$cdn()}/fe/main/interest_mo_right_4x.webp`"
            alt="banner"
            class="block w-[70px]"
            loading="lazy" />
        </div>
        <div
          class="absolute top-0 left-0 right-0 bottom-0 h-full max-w-[270px] m-auto text-white text-center text-sm font-semibold leading-tight flex flex-col items-center justify-center">
          <div>
            첫강의로 가장 많이 선택한
            <span class="text-[#ffd905]">{{ userProfileStore.profile?.selectedInterest?.description }}</span> 강의!
          </div>
          <div class="flex">
            <span class="text-[#ffd905] max-w-[90px] text-ellipsis inline-block whitespace-nowrap overflow-hidden">{{
              userProfileStore.profile?.nickName || userProfileStore.profile?.userName
            }}</span
            ><span class="text-[#ffd905] inline-block">님</span>도 최대 할인 받고 수강하세요.
          </div>
          <div class="text-[#a1bbff] text-sm font-normal">이 혜택은 곧 사라져요!</div>
        </div>
      </div>
      <div class="absolute top-0 right-0 h-full flex items-center">
        <Button
          icon="pi pi-times"
          class="mr-0 md:mr-2 top-5 right-0 md:top-0"
          text
          rounded
          @click="onCloseInterestBanner" />
      </div>
    </div>
    <div
      v-else
      class="wb-banner-top"
      :style="{ backgroundColor: Utility.getHex(topBanner?.designBackgroundColorHex || null) }">
      <div class="pc">
        <img
          :src="Utility.getOptimized(topBanner?.pcImageUrl, 'BANNER_IMG') ?? undefined"
          alt="banner"
          style="cursor: pointer"
          loading="lazy"
          @click="onClickMainBanner" />
      </div>
      <div class="mo">
        <img
          :src="Utility.getOptimized(topBanner?.mobileImageUrl, 'BANNER_IMG') ?? undefined"
          alt="banner"
          style="cursor: pointer"
          loading="lazy"
          @click="onClickMainBanner" />
      </div>
      <div class="wb-inner">
        <Button icon="pi pi-times" text rounded @click="onCloseMainBanner" />
      </div>
    </div>
  </div>
</template>
